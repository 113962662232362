import {useUpdateWorkspacePolicyById} from '@/api/rbac';
import {PolicyWorkspace, RbacErrorResponse} from '@/api/rbac/rbac.types';
import {usePolicyManager} from '@/components/admin/rbac';
import {DismissIcon, PersonIcon} from '@/components/ui/icons';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    MenuItem,
    MenuList,
    MessageBarIntent,
    Spinner,
    mergeClasses,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RoleObjectIdData} from '../rbac.types';
import useClasses from './AddRoles.styles';
import AddRolesConfirmation from './AddRolesConfirmation';
import AddRolesErrorMessage from './AddRolesErrorMessage';
import {AddRolesMessages, useAddRolesErrorHanding} from './useAddRolesErrorHanding';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface AddRolesInfoProps {
    isAddRolesOpen: boolean;
    existingMembers?: RoleObjectIdData;
    onAddRolesClose?: () => void;
    viewOnly?: boolean;
    workspacePolicy?: PolicyWorkspace;
}

export type ErrorMessageBarProps = {
    title: string;
    message: string;
    messageType: MessageBarIntent;
};

export default function AddRoles(props: AddRolesInfoProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [error, setError] = useState<AddRolesMessages>();
    const [hasError, setHasError] = useState<boolean>(false);

    const rolesObject = t('RoleAssignment.AddRoles.RolesList', {
        returnObjects: true,
    }) as {[key: string]: string};

    const roles: string[] = Object.values(rolesObject);

    const closeAddRolesDialog = () => {
        props?.onAddRolesClose?.();
    };

    useEffect(() => {
        setError(AddRolesMessages.NO_MESSAGE);
        setHasError(false);
    }, []);

    const {mutateAsync: updateWorkspacePolicy, isLoading: updatePolicyLoading} =
        useUpdateWorkspacePolicyById();

    const {workspaceName} = useWorkspaceState();

    const policyReferenceName = props?.workspacePolicy?.properties?.entity?.referenceName;

    const {togglePartnerRolesCondition} = usePolicyManager({
        workspacePolicy: props?.workspacePolicy as PolicyWorkspace,
        workspaceName: policyReferenceName as string,
    });

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const {errorMessage, generateErrorMessage} = useAddRolesErrorHanding(error as AddRolesMessages);

    const addRoles = async () => {
        const updatedPolicy = togglePartnerRolesCondition();

        if (!!updatedPolicy) {
            try {
                await updateWorkspacePolicy({
                    workspaceName: workspaceName as string,
                    policy: updatedPolicy,
                });
                closeAddRolesDialog();
                setConfirmDialogOpen(true);
            } catch (error) {
                const errorData = error as RbacErrorResponse;

                generateErrorMessage(AddRolesMessages.UPDATE_ERROR, {
                    message: errorData.Error.Message,
                });
            }
        }
    };

    return (
        <>
            <Dialog open={props.isAddRolesOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>
                            <div className={classes.titleWrapper}>
                                <span className={classes.title}>
                                    {props.viewOnly
                                        ? t('RoleAssignment.AddRoles.ViewOnlyTitle')
                                        : t('RoleAssignment.AddRoles.Title')}
                                </span>
                                <Button
                                    data-test-id="close-add-roles-dialog-button"
                                    appearance="transparent"
                                    aria-label={t('RoleAssignment.AddRoles.AriaLabels.DialogClose')}
                                    className={classes.closeButton}
                                    onClick={closeAddRolesDialog}
                                    icon={<DismissIcon />}
                                />
                            </div>
                        </DialogTitle>
                        <DialogContent
                            className={mergeClasses(
                                classes.contentSection,
                                scrollClasses.colorNeutralBackground1,
                            )}
                        >
                            <div className={classes.dialogInfo}>
                                {t('RoleAssignment.AddRoles.Info')}
                            </div>

                            <AddRolesErrorMessage error={errorMessage as ErrorMessageBarProps} />

                            <div className={classes.rolesList}>
                                <MenuList>
                                    {roles.map((role, index) => (
                                        <MenuItem
                                            className={mergeClasses(
                                                classes.customMenuItemWidth,
                                                classes.menuItemHover,
                                            )}
                                            key={index}
                                        >
                                            <div className={classes.personIcon}>
                                                <PersonIcon />
                                            </div>
                                            <span className={classes.roleName}>{role}</span>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            {!props.viewOnly ? (
                                <Button
                                    appearance="primary"
                                    onClick={addRoles}
                                    disabled={updatePolicyLoading}
                                    aria-label={t(
                                        'RoleAssignment.AddRoles.AriaLabels.AddAllRolesButton',
                                    )}
                                >
                                    {updatePolicyLoading ? (
                                        <Spinner size="tiny"></Spinner>
                                    ) : (
                                        t('RoleAssignment.AddRoles.AddAllRoles')
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    appearance="primary"
                                    onClick={closeAddRolesDialog}
                                    disabled={updatePolicyLoading}
                                    aria-label={t('RoleAssignment.AddRoles.AriaLabels.DoneButton')}
                                >
                                    {t('RoleAssignment.AddRoles.DoneButton')}
                                </Button>
                            )}
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <AddRolesConfirmation
                isConfirmDialogOpen={isConfirmDialogOpen}
                onConfirmDialogClose={handleConfirmDialogClose}
            ></AddRolesConfirmation>
        </>
    );
}
