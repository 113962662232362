import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {DismissIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import useClasses from './DisconnectCapacityDialog.styles';
import UserSettingsModal from '@/components/Navigation/UserSettingsModal';
import {Capacity} from '@/api/capacities';
import {useGetCapacityByName, usePatchCapacity} from '@/api/capacities';

interface DisconnectCapacityDialogProps {
    isDisconnectCapacityDialogOpen: boolean;
    workspaceName: string;
    previousCapacityName: string;
    newCapacityName: string;
    onClose: () => void;
}

export default function DisconnectCapacityDialog(props: DisconnectCapacityDialogProps) {
    const [isSetDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);
    const {t} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const classes = useClasses();

    const closeDisconnectCapacityDialog = () => {
        props.onClose();
    };

    // const handleDisconnectClick = () => {
    //     setCapacityValue(props.newCapacity?.properties?.numberOfUnits || 0);
    // }

    // settings modal
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const restoreFocusSourceAttribute = useRestoreFocusSource();

    useEffect(() => {
        setDisconnectCapacityDialogOpen(props.isDisconnectCapacityDialogOpen);
    }, [props.isDisconnectCapacityDialogOpen]);

    return (
        <>
            <Dialog open={isSetDisconnectCapacityDialogOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={t('ButtonLabels.Close')}
                                        icon={<DismissIcon />}
                                        onClick={closeDisconnectCapacityDialog}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <div className={classes.titleContainer}>
                                <div>
                                    {tAdmin('ManageWorkspaces.DisconnectCapacityDialog.Title', {
                                        0: props.previousCapacityName,
                                    })}
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={classes.contentSection}>
                            <div>
                                {tAdmin('ManageWorkspaces.DisconnectCapacityDialog.Description', {
                                    0: props.workspaceName,
                                })}
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                data-test-id="disconnect-capacity-button"
                                //onClick={handleDisconnectClick}
                                aria-label={tAdmin(
                                    'ManageWorkspaces.DisconnectCapacityDialog.DisconnectButton',
                                )}
                                className={classes.disconnectButton}
                                //onClick={handleYesClick}
                                //{...restoreFocusTargetAttribute}
                            >
                                {tAdmin(
                                    'ManageWorkspaces.DisconnectCapacityDialog.DisconnectButton',
                                )}
                            </Button>
                            <Button
                                data-test-id="cancel-button"
                                appearance="secondary"
                                aria-label={t('ButtonLabels.Cancel')}
                                onClick={closeDisconnectCapacityDialog}
                                //{...restoreFocusTargetAttribute}
                            >
                                {t('ButtonLabels.Cancel')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
}
