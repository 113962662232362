import React, {useState, useEffect, useRef, useCallback} from 'react';
import {setupWorker, SetupWorker} from 'msw';
import {MSW_NAMESPACE} from '../msw.constants';

import MedeinaFeatures from '@/util/features';
import {getStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import {readFromSessionStorage} from '@/util/sessionStorage.util';
import {MswSessionStorageValue} from '../msw.types';

/** Wrapper component that enables MSW  */
const MswInitializer = ({children}: any) => {
    const enableMSW = getStaticFeatureFlag(MedeinaFeatures.EnableMSW);

    // ensure that the passed in scenario is a valid scenario from our list
    const scenarioName = readFromSessionStorage<MswSessionStorageValue>(MSW_NAMESPACE)?.scenario;

    // If the feature is not turned on or there's no matching scenario, signal to render the children immediately
    const loadMsWScenario = Boolean(enableMSW && scenarioName);
    // If we don't need to load the MSW scenario, render the children immediately
    const [shouldRenderChildren, setShouldRenderChildren] = useState(!loadMsWScenario);
    const worker = useRef<SetupWorker | undefined>(undefined);

    useEffect(() => {
        // If the feature is not turned on return immediately
        // if there's no matching scenario we also return
        if (!enableMSW || !scenarioName) return;
        const setupMocks = async () => {
            try {
                console.log('Starting MSW worker with scenario:', scenarioName);
                // dynamically import the scenario handlers
                // this is done to ensure that the handlers are only loaded when needed
                const scenarioHandlers = await import(`../scenarioHandlers/${scenarioName}`);
                if (scenarioHandlers) {
                    worker.current = setupWorker(...scenarioHandlers.default);
                    await worker.current.start({
                        onUnhandledRequest: 'bypass',
                    });
                    console.log('MSW worker started');
                    setShouldRenderChildren(true);
                }
            } catch (error) {
                console.error('Failed to start MSW worker:', error);
            }
        };

        if (enableMSW && worker.current === undefined) {
            setupMocks();
        }
        // when we unmount, stop the worker
        return () => {
            if (enableMSW && worker.current && scenarioName) {
                worker.current.stop();
            }
        };
    }, [enableMSW, scenarioName]);

    return shouldRenderChildren ? children : null;
};

export default MswInitializer;
