import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {GetCapacitiesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import useFetch from '../useFetch';
import {useFeatureFlag} from '../user';
import MedeinaFeatures from '@/util/features';

export default function useGetCapacities(
    useQueryOptions?: Pick<UseQueryOptions<GetCapacitiesResponse>, 'enabled'>,
) {
    const url = `${MedeinaVariables.FidelisSecCopUri}/account/capacities?api-version=2023-12-01-preview`;
    const {customFetch} = useFetch();

    const response = useQuery({
        ...useQueryOptions,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['capacities'],
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                const error = {
                    code: response.status,
                    message: response.statusText,
                };

                throw error;
            }

            const data = await response.json();
            return data as GetCapacitiesResponse;
        },
        retry: DEFAULT_API_RETRY_COUNT,
    });
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    let testResponses;
    if (isWorkspacesTestingEnabled) {
        // make a deep copy of responses
        testResponses = JSON.parse(JSON.stringify(response));
        if (response?.data?.value) {
            testResponses.data.value = [
                ...(response?.data?.value || []),
                {
                    type: '',
                    properties: {
                        provisioningState: 'Succeeded',
                        numberOfUnits: 500,
                        crossGeoCompute: 'Allowed',
                        geo: 'us',
                    },
                    id: '',
                    name: 'capacityNumber1',
                    location: 'centralus',
                    systemData: {
                        createdByType: 'User',
                        createdAt: '2024-03-13T23:52:07.3634115Z',
                        lastModifiedByType: 'User',
                        lastModifiedAt: '2024-03-30T01:13:04.5240845Z',
                    },
                },
                {
                    type: '',
                    properties: {
                        provisioningState: 'Succeeded',
                        numberOfUnits: 500,
                        crossGeoCompute: 'Allowed',
                        geo: 'us',
                    },
                    id: '',
                    name: 'capacityNumber2',
                    location: 'uksouth',
                    systemData: {
                        createdByType: 'User',
                        createdAt: '2024-03-13T23:52:07.3634115Z',
                        lastModifiedByType: 'User',
                        lastModifiedAt: '2024-03-30T01:13:04.5240845Z',
                    },
                },
            ];
        }
    }

    return isWorkspacesTestingEnabled ? testResponses : response;
}
