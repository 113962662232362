import React, {useCallback, useMemo, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogTrigger,
    DialogSurface,
} from '@fluentui/react-components';
import {ReportFeedbackIcon, CodeIcon} from '@/components/ui/icons';
import useBreadcrumbClasses from '@/components/Navigation/Breadcrumbs/Breadcrumbs.styles';
import {
    setSessionFeatureFlags,
    getSessionFeatureFlags,
    getFeatureFlagsFromUrl,
    replaceUrlFeatureFlagsAndReload,
    clearSessionFeatureFlags,
    featureFlags,
} from '@/util/hasStaticFeatureFlags';
import {ScenarioFeatureFlagMap, MedeinaScenarios, MSW_NAMESPACE} from '@/msw/msw.constants';
import {
    deleteFromSessionStorage,
    readFromSessionStorage,
    writeToSessionStorage,
} from '@/util/sessionStorage.util';
import {MswSessionStorageValue} from '@/msw/msw.types';

{
    /**
     * A component that displays a button when url feature flags are enabled
     * and allows for viewing the feature flags as well as clearing them
     *
     * if a feature flag is set in the url, it will be stored in the session storage
     *
     * if an additional parameter is set in the url, the page will be reloaded to clear
     * the featureFlag params but preserve the other params
     */
}
function FeatureFlagIndicator() {
    const classes = useBreadcrumbClasses();

    const search = window?.location?.search;
    // get the feature flags from the url
    const urlParamFlags = useMemo<string[]>(() => {
        return getFeatureFlagsFromUrl();
    }, [search]);

    /**
     * MSW Scenario logic
     * scenarios can be set directly in the url via a query param
     * or they can be set via a feature flag with a matching scenario in the ScenarioFeatureFlagMap
     * if they have already been set and we are now reloading with the scenario in session storage
     * we will use that to show an icon in the feature flag indicator and the scenario in the dialog
     */
    const urlScenario = useMemo(() => {
        // check if the search param has a scenario
        const urlParams = new URLSearchParams(search);
        // only return the parameter if it is a valid scenario
        const includedScenario = urlParams.get('scenario');

        return includedScenario && MedeinaScenarios.includes(includedScenario)
            ? includedScenario
            : null;
    }, [search]);

    // get the feature flag scenario from the ScenarioFeatureFlagMap if it exists
    const matchedFlagScenario = useMemo(() => {
        // we only do feature flag/msw matching for a single feature flag
        if (urlParamFlags.length !== 1) {
            return null;
        }
        return ScenarioFeatureFlagMap[urlParamFlags[0]] ?? null;
    }, [urlParamFlags]);

    // get the scenario from the session storage
    const sessionScenario = useMemo(() => {
        return readFromSessionStorage<MswSessionStorageValue>(MSW_NAMESPACE)?.scenario;
    }, []);

    // get the feature flags from the session storage
    // feature flags are stored here after they are picked up from the url params and the page is refreshed
    const sessionFlags = useMemo(() => getSessionFeatureFlags(), []);

    const hasScenario = matchedFlagScenario || urlScenario;

    // if we have urlParamFlags we'll use these to override the current session flags
    useEffect(() => {
        if (urlParamFlags.length > 0 || hasScenario) {
            // test that the flags that are being set from the url are not already set in the session
            setSessionFeatureFlags(urlParamFlags);
            if (hasScenario) {
                writeToSessionStorage(MSW_NAMESPACE, {
                    scenario: matchedFlagScenario ?? urlScenario,
                });
            }
            replaceUrlFeatureFlagsAndReload();
        }
    }, [hasScenario, matchedFlagScenario, urlParamFlags, urlScenario]);

    const hasFeatureFlags = urlParamFlags.length > 0 || sessionFlags.length > 0;

    const displayFeatureFlags = useCallback(() => {
        return getSessionFeatureFlags().join(',');
    }, []);

    const handleClearFeatureFlags = useCallback(() => {
        clearSessionFeatureFlags();
        deleteFromSessionStorage(MSW_NAMESPACE);
        replaceUrlFeatureFlagsAndReload();
    }, []);

    return hasFeatureFlags || sessionScenario ? (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <li role="menuitem" className={classes.crumb} key="featureFlags">
                    <Button
                        data-testid="feature-flags-indicator"
                        size="small"
                        appearance="primary"
                        icon={sessionScenario ? <CodeIcon /> : <ReportFeedbackIcon />}
                    />
                </li>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Active Feature Flags</DialogTitle>
                    <DialogContent>
                        <div>{displayFeatureFlags()}</div>
                        {sessionScenario && (
                            <div>
                                <p>MSW Scenario: {sessionScenario}</p>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                data-testid="clear-cached-flags"
                                appearance="primary"
                                onClick={handleClearFeatureFlags}
                            >
                                Clear flags and scenarios
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    ) : null;
}

export default FeatureFlagIndicator;
