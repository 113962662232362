import React from 'react';
import {useTranslation} from 'react-i18next';
import MedeinaFeatures from '@/util/features';
import {PluginManagementInfo, usePatchPluginManagement} from '@/api/plugin';
import PluginDataTable from './PluginDataTable';
import {PluginManagementItem} from './PluginManagement.types';
import {TenantInfo} from '@/api/tenant';
import {SkillsetDescriptor, useGetSkillsets} from '@/api/skills';
import {Title2} from '@fluentui/react-components';
import useClasses from './PluginManagement.styles';

interface PluginDataTableProps {
    tenantInfo: TenantInfo | undefined;
    pluginInfo: PluginManagementInfo | undefined;
}

// Individual component that handles setting the plugin settings and rendering the error message if the update fails.
function PluginManagementList({tenantInfo, pluginInfo}: PluginDataTableProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const {data: skillsets} = useGetSkillsets();
    const {mutateAsync: patchPluginManagement} = usePatchPluginManagement();

    const [allowedPlugins, setAllowedPlugins] = React.useState<string[]>(
        pluginInfo?.allowedPlugins ?? [],
    );

    React.useEffect(() => {
        setAllowedPlugins(pluginInfo?.allowedPlugins ?? []);
    }, [pluginInfo]);

    const pluginItems: PluginManagementItem[] = React.useMemo(() => {
        const defaultSearchable: SkillsetDescriptor[] =
            skillsets?.value.filter(
                (skillset: {category: string}) => skillset.category !== 'Hidden',
            ) ?? [];

        return defaultSearchable.map((skillset) => {
            const allowed = allowedPlugins.includes(skillset.name);
            const pluginItem: PluginManagementItem = {
                descriptor: skillset,
                allowed: allowed,
            };
            return pluginItem;
        });
    }, [skillsets?.value, allowedPlugins]);

    const handleOptionChange = (pluginItem: PluginManagementItem) => {
        const pluginName = pluginItem.descriptor.name;
        if (
            (pluginItem.allowed && allowedPlugins.includes(pluginName)) ||
            (!pluginItem.allowed && !allowedPlugins.includes(pluginName))
        ) {
            return; // No change in the allowed plugins
        }

        const updatedAllowedPlugins = pluginItem.allowed
            ? [...allowedPlugins, pluginName]
            : allowedPlugins.filter((plugin) => plugin !== pluginName);

        setAllowedPlugins(updatedAllowedPlugins);

        patchPluginManagement({
            tenantId: tenantInfo?.tenantId,
            allowedPlugins: updatedAllowedPlugins,
        }).catch((error) => {
            console.error('Failed to update plugin management settings', error);
        });
    };

    if (!MedeinaFeatures.UserPluginManagementAllowed) {
        return null;
    }

    return (
        <div>
            <Title2 role="heading" className={classes.subtitle}>
                {t('SettingsPluginAvailabilityHeader')}
            </Title2>
            <PluginDataTable pluginItems={pluginItems} onPluginItemChange={handleOptionChange} />
        </div>
    );
}

export default PluginManagementList;
