import React, {useCallback, useState} from 'react';
import {
    Button,
    Tooltip,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    Dialog,
} from '@fluentui/react-components';
import {DeleteIcon, EditIcon, MoreIcon, DuplicateIcon} from '@/components/ui/icons';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import {useTranslation} from 'react-i18next';
import useClasses from './WorkspaceContextMenu.styles';
import WorkspaceForm from '../form/WorkspaceForm';
import {WorkspaceFormProps} from '../form/WorkspaceForm.types';
import {Workspace} from '@/api/workspaces';

interface WorkspaceContextMenuProps {
    workspace: Workspace;
}

/** Component that renders the options for a workspace in the data grid view */
function WorkspaceContextMenu(props: WorkspaceContextMenuProps) {
    const {workspace} = props;
    const {t} = useTranslation('admin');
    const {t: tCommon} = useTranslation('common');
    const {isSmallScreen, isMediumScreen, isLargeScreen} = useViewportSize();
    const classes = useClasses();

    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
    const [formMode, setFormMode] = useState<WorkspaceFormProps['mode']>('edit');

    const handleEdit = useCallback(() => {
        setFormMode('edit');
        setWorkspaceDialogOpen(true);
    }, []);

    const handleDuplicate = useCallback(() => {
        setFormMode('duplicate');
        setWorkspaceDialogOpen(true);
    }, []);

    const handleDelete = useCallback(() => {
        setFormMode('delete');
        setWorkspaceDialogOpen(true);
    }, []);

    return (
        <>
            <Dialog
                open={workspaceDialogOpen}
                onOpenChange={(event, data) => setWorkspaceDialogOpen(data.open)}
            >
                <WorkspaceForm
                    key={`${formMode}-${workspace.name}`}
                    mode={formMode}
                    onClose={() => setWorkspaceDialogOpen(false)}
                    workspace={workspace}
                />
            </Dialog>
            {!isSmallScreen && !isMediumScreen && !isLargeScreen ? (
                <span className={classes.buttonRow}>
                    <Tooltip
                        relationship="label"
                        content={tCommon('ButtonLabels.Edit')}
                        positioning="below"
                    >
                        <Button
                            data-testid="edit-button"
                            icon={<EditIcon />}
                            appearance="outline"
                            onClick={handleEdit}
                        ></Button>
                    </Tooltip>
                    <Tooltip
                        relationship="label"
                        content={tCommon('ButtonLabels.Duplicate')}
                        positioning="below"
                    >
                        <Button
                            data-testid="duplicate-button"
                            icon={<DuplicateIcon />}
                            appearance="outline"
                            onClick={handleDuplicate}
                        ></Button>
                    </Tooltip>
                    <Tooltip
                        relationship="label"
                        content={tCommon('ButtonLabels.Delete')}
                        positioning="below"
                    >
                        <Button
                            data-testid="delete-button"
                            className={classes.deleteButton}
                            icon={<DeleteIcon />}
                            appearance="outline"
                            onClick={handleDelete}
                        ></Button>
                    </Tooltip>
                </span>
            ) : (
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            icon={<MoreIcon />}
                            appearance="subtle"
                            aria-label={t('SessionOptionsButton')}
                        />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem icon={<EditIcon />} onClick={handleEdit}>
                                {tCommon('ButtonLabels.Edit')}
                            </MenuItem>
                            <MenuItem icon={<DuplicateIcon />} onClick={handleDuplicate}>
                                {tCommon('ButtonLabels.Duplicate')}
                            </MenuItem>
                            <MenuItem icon={<DeleteIcon />} onClick={handleDelete}>
                                {tCommon('ButtonLabels.Delete')}
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )}
        </>
    );
}

export default WorkspaceContextMenu;
