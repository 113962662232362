import useManageUsageDashboardState from './useManageUsageDashboardState';
import useManageUsageDashboardDataSources from './useManageUsageDashboardDataSources';

interface ManageUsageDashboardProps {
    capacityName: string;
}

export default function useManageUsageDashboard(props: ManageUsageDashboardProps) {
    const {
        getState,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        drillDownByDateRange,
        needsFacetRefresh,
        needsHourlyDataRefresh,
        needsDimensionalDataRefresh,
        updateSortDirection,
    } = useManageUsageDashboardState();

    const {
        hourlyDataModel,
        dimensionalDataModel,
        facetDataModel,
        isDataFacetsError,
        isHourlyCapacityUsageError,
        isDimensionalDataError,
        hourlyCapacityUsageDataUpdatedAt,
        isDimensionalDataLoading,
        dataFacetsLoading,
        hourlyCapacityUsageLoading,
        isAggregateDataFetched,
        areSearchFacetsAvailable,
    } = useManageUsageDashboardDataSources({
        capacityName: props.capacityName,
        state: getState(),
    });

    const isUsageDataLoading =
        dataFacetsLoading || hourlyCapacityUsageLoading || isDimensionalDataLoading;

    const pageLoading =
        needsDimensionalDataRefresh &&
        needsFacetRefresh &&
        needsHourlyDataRefresh &&
        isUsageDataLoading;

    const pageError = isDataFacetsError || isHourlyCapacityUsageError || isDimensionalDataError;

    const lastUpdatedAt = hourlyCapacityUsageDataUpdatedAt || new Date();

    return {
        getState,
        hourlyDataModel,
        dimensionalDataModel,
        facetDataModel,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        updateSortDirection,
        drillDownByDateRange,
        pageLoading,
        isEvaluationAggregateDataLoading: !isAggregateDataFetched,
        pageError,
        lastUpdatedAt,
        dateRangeOption: getState()?.dateText ?? '',
        areSearchFacetsAvailable,
    };
}
