import { makeResetStyles, makeStyles, mergeClasses } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const messageBarActionsClassNames = {
    root: 'fui-MessageBarActions',
    containerAction: 'fui-MessageBarActions__containerAction'
};
/**
 * Styles for the root slot
 */ const useRootBaseStyles = makeResetStyles({
    gridArea: 'secondaryActions',
    display: 'flex',
    columnGap: tokens.spacingHorizontalM,
    paddingRight: tokens.spacingHorizontalM
});
const useContainerActionBaseStyles = makeResetStyles({
    gridArea: 'actions',
    paddingRight: tokens.spacingHorizontalM
});
const useMultilineStyles = makeStyles({
    root: {
        justifyContent: 'end',
        marginTop: tokens.spacingVerticalMNudge,
        marginBottom: tokens.spacingVerticalS,
        marginRight: '0px',
        paddingRight: tokens.spacingVerticalM
    },
    noActions: {
        display: 'none'
    }
});
/**
 * Apply styling to the MessageBarActions slots based on the state
 */ export const useMessageBarActionsStyles_unstable = (state)=>{
    'use no memo';
    const rootBaseStyles = useRootBaseStyles();
    const containerActionBaseStyles = useContainerActionBaseStyles();
    const multilineStyles = useMultilineStyles();
    state.root.className = mergeClasses(messageBarActionsClassNames.root, rootBaseStyles, state.layout === 'multiline' && multilineStyles.root, !state.hasActions && multilineStyles.noActions, state.root.className);
    if (state.containerAction) {
        state.containerAction.className = mergeClasses(messageBarActionsClassNames.containerAction, containerActionBaseStyles, state.containerAction.className);
    }
    return state;
};
