import {useMemo} from 'react';
import {useRoutes, Navigate, RouteObject} from 'react-router-dom';
import {AuthorizedTemplate} from '@/components/msal';
import Home from './index';
import SessionList from './sessions/index';
import Session from './sessions/[sessionId]';
import {UnauthenticatedTemplate} from '@azure/msal-react';
import SignInSignOutButton from '@/components/SignInSignOutButton';
import {useFeatureFlags} from '@/api/user';
import MedeinaFeatures, {MedeinaUrlParamFeatures} from '@/util/features';
import {MedeinaRoutesMap} from '@/components/Navigation/MedeinaRoutes';
import Dev from '@/components/sections/dev/Dev';

// Tour pages
import AnalystTour from '@/components/sections/tours/analyst/TourContainer';
import AdminTourGA from '@/components/sections/tours/admin-ga/TourContainer';

import CallbackPage from '@/components/sections/connector/_3pOAuthRedirect';
import NewSession from '@/components/sections/sessions/NewSession';

// Promptbook pages
import PromptbookList from '@/components/sections/promptbooks/PromptbookList';
import {OwnerSettings} from '@/components/admin';
import {ManageWorkspaces} from '@/components/sections/workspaces';
import UsageDashboard from '@/components/sections/admin/UsageDashboard/UsageDashboard';
import {RoleAssignment} from '@/components/admin';
import UsageDashboardV2 from '@/components/sections/admin/UsageDashboard/UsageDashboardV2';
import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';

import PluginSettingsPage from '@/components/admin/PluginSettingsPage';

export const routeConfig: RouteObject[] = [
    {
        path: MedeinaRoutesMap.home.path,
        id: MedeinaRoutesMap.home.id,
        element: (
            <>
                <AuthorizedTemplate>
                    <Home />
                </AuthorizedTemplate>
                <UnauthenticatedTemplate>
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <SignInSignOutButton />
                    </div>
                </UnauthenticatedTemplate>
            </>
        ),
    },
    {
        path: MedeinaRoutesMap.mysessions.path,
        element: (
            <AuthorizedTemplate>
                <SessionList />
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.newsessions.path,
        element: (
            <AuthorizedTemplate>
                {MedeinaFeatures.NewSessionPage ? <NewSession /> : <Navigate to="/" />}
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.session.path,
        element: (
            <AuthorizedTemplate>
                <Session />
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.promptbooks.path,
        element: (
            <AuthorizedTemplate>
                <PromptbookList />
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.promptbook.path,
        element: (
            <AuthorizedTemplate>
                <PromptbookList />
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.admintour.path,
        element: (
            <AuthorizedTemplate isAdminViewable={true}>
                <AdminTourGA />
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.analysttour.path,
        element: (
            <AuthorizedTemplate>
                <AnalystTour />
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.authcallback.path,
        element: <CallbackPage />,
    },
];

// GA Launch stage 1b: Enable role assignment for Fidelis-migrated EAP and GA customers.
const routesFidelisDisableEapFre: RouteObject[] = [];

// GA Launch stage 2: Enable role assignment for Fidelis-migrated EAP and GA customers.
const routesFidelisRBAC: RouteObject[] = [
    // Role assignments goes here.
    {
        path: MedeinaRoutesMap.roleassignment.path,
        element: (
            <AuthorizedTemplate isAdminViewable={true}>
                <RoleAssignment />
            </AuthorizedTemplate>
        ),
    },
];

// GA Launch stage 3a: Enable GA features that require Fidelis (for GA and Fidelis-migrated EAP customers).
const routesFidelisGA: RouteObject[] = [
    {
        path: MedeinaRoutesMap.ownersettings.path,
        element: (
            <AuthorizedTemplate isAdminViewable={true}>
                <OwnerSettings />
            </AuthorizedTemplate>
        ),
    },
    {
        path: MedeinaRoutesMap.usagemonitoring.path,
        element: (
            <AuthorizedTemplate isAdminViewable={true}>
                <UsageDashboard />
            </AuthorizedTemplate>
        ),
    },
];

if (
    MedeinaFeatures.UsageMonitoringV2 &&
    hasStaticFeatureFlag(MedeinaUrlParamFeatures.ViewUsageMonitoringv2)
) {
    routesFidelisGA.push({
        path: MedeinaRoutesMap.usagemonitoringv2.path,
        element: (
            <AuthorizedTemplate isAdminViewable={true}>
                <UsageDashboardV2 />
            </AuthorizedTemplate>
        ),
    });
}

// GA Launch stage 3b: Enable GA features that don't require Fidelis (for everyone, including Fidelis opt-out EAP customers).
const routesNonFidelisGA: RouteObject[] = [
    // TBD
];

export default function Routes() {
    // Determine available routes based on user feature access.
    const [
        _,
        hasFidelisRBAC,
        hasFidelisGA,
        hasNonFidelisGA,
        hasWorkspacesEnabled,
        hasMSWEnabled,
        hasUserPluginManagement,
    ] = useFeatureFlags([
        MedeinaFeatures.LaunchExperienceFidelisRBAC,
        MedeinaFeatures.LaunchExperienceFidelisGA,
        MedeinaFeatures.LaunchExperienceNonFidelisGA,
        MedeinaFeatures.WorkspacesEnabled,
        MedeinaFeatures.EnableMSW,
        MedeinaFeatures.UserPluginManagementAllowed,
    ]);

    const routes = useMemo(() => {
        let nextRoutes: RouteObject[] = routeConfig.slice(0);

        if (hasFidelisRBAC) {
            nextRoutes.push(...routesFidelisRBAC);
        }

        if (hasFidelisGA) {
            nextRoutes.push(...routesFidelisGA);
        }

        if (hasNonFidelisGA) {
            nextRoutes.push(...routesNonFidelisGA);
        }

        if (hasWorkspacesEnabled) {
            nextRoutes.push({
                path: MedeinaRoutesMap.manageworkspaces.path,
                element: (
                    <AuthorizedTemplate isAdminViewable={true}>
                        <ManageWorkspaces />
                    </AuthorizedTemplate>
                ),
            });
        }

        if (hasMSWEnabled) {
            nextRoutes.push({
                path: MedeinaRoutesMap.dev.path,
                element: (
                    <AuthorizedTemplate>
                        <Dev />
                    </AuthorizedTemplate>
                ),
            });
        }

        if (hasUserPluginManagement) {
            nextRoutes.push({
                path: MedeinaRoutesMap.pluginsettings.path,
                element: (
                    <AuthorizedTemplate isAdminViewable={true}>
                        <PluginSettingsPage />
                    </AuthorizedTemplate>
                ),
            });
        }

        return nextRoutes;
    }, [hasFidelisRBAC, hasFidelisGA, hasNonFidelisGA, hasWorkspacesEnabled, hasMSWEnabled]);

    return useRoutes(routes);
}
