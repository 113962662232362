import React, {useEffect, useState} from 'react';
import {
    Menu,
    MenuList,
    MenuTrigger,
    MenuPopover,
    MenuGroup,
    MenuDivider,
    MenuGroupHeader,
    MenuItem,
    MenuButton,
    useRestoreFocusTarget,
    useRestoreFocusSource,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Subtitle2,
    Button,
} from '@fluentui/react-components';
import UserSettingsModal from '@/components/Navigation/UserSettingsModal';
import {DividerShort16Icon} from '@/components/ui/icons';
import {useNavigate} from 'react-router-dom';
import {
    AddIcon,
    CheckmarkIcon,
    DataUsageIcon,
    WorkspacesIcon,
    SettingsIcon,
    DismissIcon,
} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {useGetUserInfo} from '@/api/app';
import useTourClasses from '@/components/sections/tours/Tour.styles';
import useStyles from './NewWorkspacePopover.styles';
import SetPreferredWorkspaceDialog from '@/components/SetPreferredWorkspaceDialog';

/** Dropdown menu for Workspace selection */
function WorkspaceSelector() {
    const {t: tAdmin} = useTranslation('admin');
    const {t} = useTranslation('common');
    // feature flag
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    // retrieve workspace and user data
    const {workspaceName, ...rest} = useWorkspaceState();
    const {data: userAuthInfo} = useGetUserInfo();
    const {
        data: workspacesData,
        isFetched: isWorkspacesDataFetched,
        isError: isWorkspacesDataError,
        isLoading: isWorkspacesLoading,
        error: workspacesError,
    } = useGetWorkspaces();
    const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceName);
    useEffect(() => {
        setSelectedWorkspace(workspaceName);
    }, [workspaceName]);
    // settings modal
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    // change workspace
    const handleMenuItemClick = (workspace: Workspace) => {
        setSelectedWorkspace(workspace.name);
        //onWorkspaceChange(workspace);
    };
    // preferred workspace alert
    const [isPreferredWorkspaceDialogOpen, setPreferredWorkspaceDialogOpen] =
        useState<boolean>(false);
    const fillerIcon = <span style={{width: '16px', display: 'inline-block'}} />;
    const navigate = useNavigate();
    const tourClasses = useTourClasses();
    const classes = useStyles();
    const [openPopover, setOpenPopover] = useState(true);

    return isWorkspacesTestingEnabled ? (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <DividerShort16Icon />
            <Menu>
                <Popover withArrow open={openPopover}>
                    <PopoverTrigger>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton appearance="subtle" size="medium">
                                {selectedWorkspace}
                            </MenuButton>
                        </MenuTrigger>
                    </PopoverTrigger>
                    <PopoverSurface>
                        <div className={classes.popOver}>
                            <div className={classes.stack}>
                                <Subtitle2>{t('NewWorkspacePopoverTitle')}</Subtitle2>
                                <Button
                                    data-test-id="close-new-workspace-popover-button"
                                    appearance="subtle"
                                    aria-label={t(
                                        'RoleAssignment.AddRoleMembers.AriaLabels.AddRoleMemberDialogClose',
                                    )}
                                    className={classes.closeButton}
                                    onClick={() => setOpenPopover(false)}
                                    icon={<DismissIcon />}
                                />
                            </div>
                            <p className={tourClasses.popoverText}>
                                {t('NewWorkspacePopoverDescription')}
                            </p>
                        </div>
                    </PopoverSurface>
                </Popover>
                <MenuPopover>
                    <MenuList>
                        <MenuGroup>
                            <MenuGroupHeader>
                                {tAdmin('ManageWorkspaces.MenuButtons.Workspaces')}
                            </MenuGroupHeader>
                            {workspacesData?.value?.map((workspace: Workspace, index: number) => (
                                <MenuItem
                                    key={index}
                                    icon={
                                        workspace.name === selectedWorkspace ? (
                                            <CheckmarkIcon />
                                        ) : (
                                            fillerIcon
                                        )
                                    }
                                    onClick={() => handleMenuItemClick(workspace)}
                                >
                                    {workspace.name}
                                </MenuItem>
                            ))}
                        </MenuGroup>
                        {(userAuthInfo?.isAdmin ?? false) && (
                            <>
                                <MenuDivider />
                                <MenuGroup>
                                    <MenuItem
                                        icon={<WorkspacesIcon />}
                                        onClick={() => navigate('/manage-workspaces')}
                                    >
                                        {tAdmin('ManageWorkspaces.MenuButtons.ManageWorkspaces')}
                                    </MenuItem>
                                    <MenuItem
                                        icon={<DataUsageIcon />}
                                        onClick={() => navigate('/usage-monitoring')}
                                    >
                                        {tAdmin('ManageWorkspaces.MenuButtons.CapacityUsage')}
                                    </MenuItem>
                                    <MenuItem icon={<AddIcon />}>
                                        {tAdmin('ManageWorkspaces.MenuButtons.NewWorkspace')}
                                    </MenuItem>
                                    <MenuItem
                                        icon={<SettingsIcon />}
                                        onClick={() => setPreferredWorkspaceDialogOpen(true)}
                                        {...restoreFocusTargetAttribute}
                                    >
                                        Show preferred workspace alert
                                    </MenuItem>
                                    <MenuItem
                                        icon={<SettingsIcon />}
                                        onClick={() => setSettingsOpen(true)}
                                        {...restoreFocusTargetAttribute}
                                    >
                                        {tAdmin(
                                            'ManageWorkspaces.MenuButtons.SetPreferredWorkspace',
                                        )}
                                    </MenuItem>
                                </MenuGroup>
                            </>
                        )}
                    </MenuList>
                </MenuPopover>
            </Menu>
            <SetPreferredWorkspaceDialog
                isSetPreferredWorkspaceDialogOpen={isPreferredWorkspaceDialogOpen}
                onClose={() => {
                    setPreferredWorkspaceDialogOpen(false);
                }}
            ></SetPreferredWorkspaceDialog>
            <UserSettingsModal
                data-testid="SettingsButton"
                open={settingsOpen}
                setOpen={setSettingsOpen}
                setMenuOpen={setOpen}
                {...restoreFocusSourceAttribute}
            />
        </div>
    ) : null;
}

export default WorkspaceSelector;
