import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {DismissIcon} from '@/components/ui/icons';
import {Button, MenuItem, MenuList, MenuPopover} from '@fluentui/react-components';
import useClasses from './FilterPills.styles';
import {SearchCategory} from '../UsageDashboard.types';
import {getDisplayValueForCopilotExperience, getUserDisplayValue} from './Filter.utils';

interface PillMenuItemsProps {
    index: number;
    filter: SelectedSearchFacet;
    onFilterRemoval: (category: string, value: string) => void;
    copilotExperienceMap: KeyValueFilterDetails[];
}

export const PillMenuItems: React.FC<PillMenuItemsProps> = (props: PillMenuItemsProps) => {
    const classes = useClasses();

    return (
        <MenuPopover key={props.index}>
            <MenuList key={props.index}>
                {props?.filter?.values?.map((value, valueIndex) => (
                    <MenuItem key={valueIndex} className={classes.menuItem}>
                        <span className={classes.nestedMenuButton}>
                            {props.filter.category == SearchCategory.Users
                                ? getUserDisplayValue(value)
                                : props.filter.category == SearchCategory.CopilotExperience
                                ? getDisplayValueForCopilotExperience(
                                      value,
                                      props.copilotExperienceMap,
                                  )
                                : value}
                        </span>
                        <Button
                            icon={<DismissIcon />}
                            size="small"
                            appearance="transparent"
                            onClick={() => props.onFilterRemoval(props.filter.category, value)}
                            className={classes.dismissButton}
                        />
                    </MenuItem>
                ))}
            </MenuList>
        </MenuPopover>
    );
};
