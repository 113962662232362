import {WorkspaceFormProps} from './WorkspaceForm.types';
import {DialogContent, DialogActions, DialogTrigger, Button} from '@fluentui/react-components';
import React from 'react';

function Delete(props: WorkspaceFormProps) {
    return (
        <>
            <DialogContent>Are you sure you want to delete this workspace?</DialogContent>
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">Cancel</Button>
                </DialogTrigger>
                <Button appearance="primary">Delete</Button>
            </DialogActions>
        </>
    );
}

export default Delete;
