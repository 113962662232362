export const MSW_NAMESPACE = 'medeina_msw';

export const MedeinaScenarios = ['helloWorld', 'multiWorkspaces', 'default'];

export const ScenarioFeatureFlagMap: Record<string, string> = {
    // workspaces is the only feature that has a scenario associated with it
    // WorkspacesEnabled: MedeinaScenarios[1],

    // hello world is an api demo
    HelloWorld: MedeinaScenarios[0],
};
