import {DialogContent, DialogActions, DialogTrigger, Button} from '@fluentui/react-components';
import React, {useCallback} from 'react';
import {WorkspaceFormProps} from './WorkspaceForm.types';
import useClasses from './WorkspaceForm.styles';
import CapacitySelectField from './CapacitySelect';
import StorageLocation from './StorageLocationField';
import WorkspaceName from './WorkspaceNameField';
import ModelImprovement from './ModelImprovementField';
import ProductImprovement from './ProductImprovementField';
import TermsConditions from './TermsConditionsField';
import {useTranslation} from 'react-i18next';

function CreateEdit(props: WorkspaceFormProps) {
    const {mode, onCancel, onClose, onDelete, workspace} = props;
    const classes = useClasses();
    const showDelete = mode === 'edit' && onDelete;
    const {t} = useTranslation();
    const {t: tCommon} = useTranslation('common');

    // field state values
    const [workspaceName, setWorkspaceName] = React.useState('');
    const [dataStorageLocation, setDataStorageLocation] = React.useState('');
    const [capacity, setCapacity] = React.useState('');
    const [modelImprovement, setModelImprovement] = React.useState(true);
    const [productImprovement, setProductImprovement] = React.useState(true);
    const [termsConditions, setTermsConditions] = React.useState(false);

    const handleFormSubmit = useCallback((ev: React.FormEvent) => {
        // prevent the form from firing a submit event
        ev.preventDefault();
        console.log(ev);
    }, []);

    return (
        <form onSubmit={handleFormSubmit}>
            <DialogContent className={classes.dialogContent}>
                <CapacitySelectField
                    value={capacity}
                    onOptionSelect={(capacity) => setCapacity(capacity.name)}
                    required
                />
                <StorageLocation
                    value={dataStorageLocation}
                    onOptionSelect={(selectedLocation) => setDataStorageLocation(selectedLocation)}
                />
                <WorkspaceName value={workspaceName} preventDuplicate />
                <ProductImprovement
                    value={productImprovement}
                    onChange={(checked) => setProductImprovement(checked)}
                />
                <ModelImprovement
                    value={modelImprovement}
                    onChange={(checked) => setModelImprovement(checked)}
                />
                <TermsConditions
                    value={termsConditions}
                    onChange={(checked) => setTermsConditions(checked)}
                    required
                />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.leftActions}>
                    {showDelete && (
                        <Button appearance="secondary" onClick={onDelete}>
                            {tCommon('ButtonLabels.Delete')}
                        </Button>
                    )}
                </div>
                <div className={classes.rightActions}>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">{t('ButtonLabels.Cancel')}</Button>
                    </DialogTrigger>
                    <Button appearance="primary" type="submit">
                        {tCommon('ButtonLabels.Create')}
                    </Button>
                </div>
            </DialogActions>
        </form>
    );
}

export default CreateEdit;
