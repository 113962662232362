import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {GetWorkspacesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import useFetch from '../useFetch';
import {useFeatureFlag} from '../user';
import MedeinaFeatures from '@/util/features';

export default function useGetWorkspaces(
    useQueryOptions?: Pick<UseQueryOptions<GetWorkspacesResponse>, 'enabled' | 'refetchOnMount'>,
) {
    const url = `${MedeinaVariables.FidelisSecCopUri}/account/workspaces?api-version=2023-12-01-preview`;
    const {customFetch} = useFetch();
    const response = useQuery<GetWorkspacesResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['workspaces'],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                const error = {
                    code: response.status,
                    message: response.statusText,
                };

                throw error;
            }

            const data = await response.json();
            return data as GetWorkspacesResponse;
            //return data as GetWorkspacesResponse;
        },
        ...useQueryOptions,
    });
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    let testResponses;
    if (isWorkspacesTestingEnabled) {
        // make a deep copy of responses
        testResponses = JSON.parse(JSON.stringify(response));
        if (response?.data?.value) {
            testResponses.data.value = [
                ...(response?.data?.value || []),
                {
                    name: 'Elm',
                    workspaceOptInConfig: {
                        isAllowProductImprovement: 'true',
                        isAllowModelImprovement: 'true',
                    },
                    capacity: {
                        type: 'CapacityReference',
                        referenceName: 'defaultCapacity',
                    },
                    systemData: {
                        createdBy: 'f9a7c3a6-8a6d-4fc8-86ec-74cec92b0ccd',
                        createdByType: 'User',
                        createdAt: '2024-03-13T23:59:43.1095959Z',
                        lastModifiedBy: '3f97007b-193d-4494-b47f-e20d4597a8bf',
                        lastModifiedByType: 'User',
                        lastModifiedAt: '2024-05-01T01:07:04.2924204Z',
                    },
                },
                {
                    name: 'Arbutus',
                    workspaceOptInConfig: {
                        isAllowProductImprovement: 'true',
                        isAllowModelImprovement: 'true',
                    },
                    capacity: {
                        type: 'CapacityReference',
                        referenceName: 'defaultCapacity',
                    },
                    systemData: {
                        createdBy: 'f9a7c3a6-8a6d-4fc8-86ec-74cec92b0ccd',
                        createdByType: 'User',
                        createdAt: '2023-03-13T23:59:43.1095959Z',
                        lastModifiedBy: '3f97007b-193d-4494-b47f-e20d4597a8bf',
                        lastModifiedByType: 'User',
                        lastModifiedAt: '2024-07-22T01:07:04.2924204Z',
                    },
                },
            ];
        }
    }
    return isWorkspacesTestingEnabled ? testResponses : response;
}
