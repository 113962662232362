import {useEffect, useState} from 'react';
import useClasses from './ManageWorkspaces.styles';
import {formatDistanceToNow} from 'date-fns';
import {REGION_DATA} from '@/components/App.constants';
import {RegionName} from '@/components/App.types';
import {useNavigate} from 'react-router-dom';
import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridCellFocusMode,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    TableCellLayout,
    TableColumnDefinition,
    TableColumnId,
    TableRowId,
    createTableColumn,
    TableColumnSizingOptions,
    mergeClasses,
    TableCellActions,
    Menu,
    MenuButton,
    MenuTrigger,
    MenuGroup,
    MenuGroupHeader,
    MenuDivider,
    MenuPopover,
    MenuList,
    MenuItem,
    Link,
    Dialog,
} from '@fluentui/react-components';
import {Capacity} from '@/api/capacities/capacities.types';
import MedeinaVariables from '@/util/variables';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {useGetCapacityByName, useGetCapacities} from '@/api/capacities';
import {DataGridSortIcon, AddIcon, OpenIcon, CheckmarkIcon} from '@/components/ui/icons';
import Highlighter from 'react-highlight-words';
import {useTranslation} from 'react-i18next';
import {useGetUserInfo} from '@/api/app';

import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useGetRoleMembers} from '@/api/rbac';
import {CapacityWarningMessageBar} from './CapacityWarningMessageBar';
import {WorkspaceWarningMessageBar} from './WorkspaceWarningMessageBar';
import {GetRoleMembersResponse, useGetRoleMemberDetails} from '@/api/rbac';
import RoleGroup from '@/components/admin/rbac/RoleGroup';
import {
    Condition,
    PolicyWorkspace,
    RoleMember,
    RoleMembers,
    RoleMemberIds,
    GetRoleByNameResponse,
    MemberResponseItem,
} from '@/api/rbac/rbac.types';
import DisconnectCapacityDialog from './DisconnectCapacityDialog';
import {RoleObjectIdData} from '@/components/admin/rbac/rbac.types';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import WorkspaceForm from '@/components/sections/workspaces/form/WorkspaceForm';
import WorkspaceContextMenu from './WorkspaceContextMenu';

//import useGetWorkspaceLink from '@/api/workspaces/useGetWorkspaceLink';
const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
    switch (columnId) {
        case 'name':
        case 'capacity':
        case 'lastupdated':
        case 'created':
        case 'owners':
        case 'contributors':
        default:
            return 'cell';
    }
};
export default function ManageWorkspaces() {
    //const [capacities, setCapacities] = useState<Array<{workspace: string; capacity: string}>>([]);
    const classes = useClasses();
    const {data: user} = useGetUserInfo();
    const [searchText, setSearchText] = useState<string>('');
    const [correspondingWorkspace, setCorrespondingWorkspace] = useState<string>('');
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
    const {t} = useTranslation('admin');
    const {t: tCommon} = useTranslation('common');
    const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>(undefined));
    const onSelectionChange: DataGridProps['onSelectionChange'] = (e: any, data: any) => {
        setSelectedRows(data.selectedItems);
    };
    const {
        data: workspacesData,
        isFetched: isWorkspacesDataFetched,
        isError: isWorkspacesDataError,
        isLoading: isWorkspacesLoading,
        error: workspacesError,
    } = useGetWorkspaces();

    const {isSmallScreen, isMediumScreen, isLargeScreen} = useViewportSize();

    // members logic
    const {workspaceName} = useWorkspaceState();
    const [existingMembers, setExistingMembers] = useState<RoleObjectIdData>();
    const [policyProcessed, setPolicyProcessed] = useState<boolean>(false);
    const [isGraphDataError, setGraphDataError] = useState<boolean>(false);
    const [isGraphDataLoading, setIsGraphDataLoading] = useState<boolean>(false);
    const [isAddRoleMemberError, setAddRoleMemberError] = useState<boolean>(false);
    const [referenceNameError, setReferenceNameError] = useState<boolean>(false);
    const performAddRoleMembersActions = (roleObjectIdData: RoleObjectIdData) => {
        setExistingMembers(roleObjectIdData);
        setPolicyProcessed(true);
    };
    const onGraphDataError = () => {
        setGraphDataError(true);
    };
    const {
        data: dataShareResponse,
        isLoading: dataShareLoading,
        isFetched: dataShareFetched,
        isSuccess: dataShareIsSuccessful,
        isError: dataShareError,
        isError: dataShareIsError,
        isRefetching: dataShareRefetching,
    } = useGetRoleMembers(workspaceName || '', {enabled: !!workspaceName});
    const isDataLoading =
        dataShareLoading || dataShareRefetching || isGraphDataLoading || !policyProcessed;
    const [policyReferenceName, setPolicyReferenceName] = useState<string>('');
    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);

    useEffect(() => {
        if (dataShareIsSuccessful) {
            if (dataShareResponse.properties.entity.referenceName!!) {
                setPolicyReferenceName(dataShareResponse.properties.entity.referenceName);
            } else {
                setReferenceNameError(true);
            }
        }
    }, [dataShareFetched, dataShareIsSuccessful]);
    //////////////

    const {data: capacities, isSuccess: isGetCapacitiesSuccess} = useGetCapacities({});
    let capacitiesArray: Capacity[] = [];
    // loop through the available capacities in the capacities array
    for (let i = 0; i < (capacities?.value.length ?? 0); i++) {
        capacitiesArray.push(capacities?.value?.[i] as Capacity);
    }
    const renderRelativeTime = (timestamp: string) => {
        const date = new Date(timestamp);
        return formatDistanceToNow(date, {addSuffix: true});
    };
    const columnSizingOptions: TableColumnSizingOptions = {
        name: {idealWidth: 287},
        capacity: {idealWidth: 208, minWidth: 150},
        lastupdated: {idealWidth: 200, minWidth: 150},
        created: {idealWidth: 200, minWidth: 150},
        owners: {idealWidth: 200},
        contributors: {idealWidth: 300},
    };
    const fillerIcon = <span style={{width: '16px', display: 'inline-block'}} />;
    const columns: TableColumnDefinition<Workspace>[] = [
        createTableColumn<Workspace>({
            columnId: 'name',
            renderHeaderCell: () => {
                return (
                    <DataGridHeaderCell sortIcon={<DataGridSortIcon />}>
                        {t('ManageWorkspaces.TableHeaders.Name')}
                    </DataGridHeaderCell>
                );
            },
            renderCell: (workspace) => {
                return (
                    <>
                        <TableCellLayout
                            className={classes.overflowNameContent}
                            data-testid="workspace-name"
                        >
                            <Highlighter
                                highlightClassName={classes.highlight}
                                searchWords={[searchText]}
                                textToHighlight={workspace.name}
                            />
                        </TableCellLayout>
                        <TableCellActions
                            onClick={(e: {
                                preventDefault: () => void;
                                stopPropagation: () => void;
                            }) => {
                                // We do not want row selection when the table-cell-action button is pressed.
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            aria-haspopup="true"
                        >
                            <WorkspaceContextMenu workspace={workspace} />
                        </TableCellActions>
                    </>
                );
            },
        }),
        createTableColumn<Workspace>({
            columnId: 'capacity',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Capacity'),

            renderCell: (workspace) => {
                /* eslint-disable react-hooks/rules-of-hooks */
                const {
                    data: capacityData,
                    // isFetched: isCapacityDataFetched,
                    // isSuccess: isCapacityDataSuccess,
                    // isLoading: isCapacitiesDataLoading,
                    // isError: isCapacityDataError,
                    // error: capacityError,
                } = useGetCapacityByName(workspace?.capacity?.referenceName || '', {
                    enabled: isWorkspacesDataFetched, //&& isWorkspaceDataFetched, - TODO - uncomment when we have the data
                    refetchOnMount: true,
                });
                const capacityDisplayName =
                    REGION_DATA[capacityData?.location as RegionName]?.displayName;
                // for capacity change
                const [selectedCapacity, setSelectedCapacity] =
                    useState<string>(capacityDisplayName);
                const [previousCapacity, setPreviousCapacity] = useState<string>(selectedCapacity);

                useEffect(() => {
                    setSelectedCapacity(capacityDisplayName);
                }, [capacityDisplayName]);

                const handleMenuItemClick = (capacity: Capacity) => {
                    setPreviousCapacity(selectedCapacity);
                    setSelectedCapacity(REGION_DATA[capacity?.location as RegionName]?.displayName);
                    setDisconnectCapacityDialogOpen(true);
                    setCorrespondingWorkspace(workspace.name);
                };
                let hasError = false;
                if (workspace.name == 'Elm') {
                    hasError = true;
                }
                /* eslint-enable react-hooks/rules-of-hooks */
                return (
                    <>
                        <TableCellLayout>
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    {hasError ? (
                                        <MenuButton
                                            appearance="transparent"
                                            data-testid="capacity-menu-button"
                                            className={classes.errorMenuButton}
                                        >
                                            {t('ManageWorkspaces.MenuButtons.NoCapacitySelected')}
                                        </MenuButton>
                                    ) : (
                                        <MenuButton
                                            appearance="transparent"
                                            data-testid="capacity-menu-button"
                                        >
                                            {selectedCapacity}
                                        </MenuButton>
                                    )}
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuGroup>
                                            <MenuGroupHeader>
                                                {t('ManageWorkspaces.MenuButtons.CapacitiesYouOwn')}
                                            </MenuGroupHeader>
                                            {hasError
                                                ? capacitiesArray.map(
                                                      (capacity: Capacity, index: number) => (
                                                          <MenuItem
                                                              key={index}
                                                              // icon={
                                                              //     REGION_DATA[
                                                              //         capacity?.location as RegionName
                                                              //     ]?.displayName === selectedCapacity ? (
                                                              //         <CheckmarkIcon />
                                                              //     ) : (
                                                              //         fillerIcon
                                                              //     )
                                                              // }
                                                              className={classes.errorMenuButton}
                                                              onClick={() =>
                                                                  handleMenuItemClick(capacity)
                                                              }
                                                          >
                                                              {REGION_DATA[
                                                                  capacity.location as RegionName
                                                              ]?.displayName +
                                                                  ' ' +
                                                                  t(
                                                                      'ManageWorkspaces.MenuButtons.NotConnectedError',
                                                                  )}
                                                          </MenuItem>
                                                      ),
                                                  )
                                                : capacitiesArray.map(
                                                      (capacity: Capacity, index: number) => (
                                                          <MenuItem
                                                              key={index}
                                                              icon={
                                                                  REGION_DATA[
                                                                      capacity?.location as RegionName
                                                                  ]?.displayName ===
                                                                  selectedCapacity ? (
                                                                      <CheckmarkIcon />
                                                                  ) : (
                                                                      fillerIcon
                                                                  )
                                                              }
                                                              onClick={() =>
                                                                  handleMenuItemClick(capacity)
                                                              }
                                                          >
                                                              {
                                                                  REGION_DATA[
                                                                      capacity.location as RegionName
                                                                  ]?.displayName
                                                              }
                                                          </MenuItem>
                                                      ),
                                                  )}
                                        </MenuGroup>
                                        <MenuDivider />
                                        <MenuGroup>
                                            <MenuItem
                                                icon={<AddIcon />}
                                                data-testid="new-capacity-menu-item"
                                            >
                                                {t('ManageWorkspaces.MenuButtons.NewCapacity')}
                                            </MenuItem>
                                        </MenuGroup>
                                        <MenuDivider />
                                        <MenuGroup>
                                            <MenuItem
                                                icon={<OpenIcon />}
                                                data-testid="manage-capacities-menu-item"
                                            >
                                                <Link
                                                    href={MedeinaVariables.ManageCapacityAzureUri}
                                                    target="_blank"
                                                    aria-label={t(
                                                        'ManageWorkspaces.ariaLabel.ManageCapacityAzureUri',
                                                    )}
                                                    appearance="subtle"
                                                >
                                                    {t(
                                                        'ManageWorkspaces.MenuButtons.ManageCapacitiesAzure',
                                                    )}
                                                </Link>
                                            </MenuItem>
                                        </MenuGroup>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            <DisconnectCapacityDialog
                                isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                                workspaceName={correspondingWorkspace}
                                previousCapacityName={previousCapacity}
                                newCapacityName={selectedCapacity}
                                onClose={() => {
                                    setDisconnectCapacityDialogOpen(false);
                                }}
                            />
                        </TableCellLayout>
                    </>
                );
            },
        }),
        createTableColumn<Workspace>({
            columnId: 'lastupdated',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.LastUpdated'),
            renderCell: (workspace) => (
                <TableCellLayout>
                    <div className={classes.overflowContent}>
                        <span>{renderRelativeTime(workspace.systemData.lastModifiedAt ?? '')}</span>
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn<Workspace>({
            columnId: 'created',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Created'),
            renderCell: (workspace) => (
                <TableCellLayout>
                    <div className={classes.overflowContent}>
                        <span>{renderRelativeTime(workspace.systemData.createdAt ?? '')}</span>
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn<Workspace>({
            columnId: 'owners',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Owners'),
            renderCell: (workspace) => (
                <TableCellLayout>
                    <div className={classes.overflowContent}>
                        <div>
                            {/* <RoleGroup
                                werePoliciesSuccessfullyFetched={dataShareIsSuccessful}
                                workspacePolicy={dataShareResponse as PolicyWorkspace}
                                onPolicySuccessfullyProcessed={performAddRoleMembersActions}
                                dataRefetching={dataShareRefetching}
                                onGraphDataError={onGraphDataError}
                                onGraphDataLoaded={() => setIsGraphDataLoading(false)}
                                onGraphDataLoading={() => setIsGraphDataLoading(true)}
                                isAddRoleMemberError={isAddRoleMemberError}
                                isDataLoading={isDataLoading}
                                policyReferenceName={policyReferenceName}
                                userRole={'owner'}
                            /> */}
                        </div>
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn<Workspace>({
            columnId: 'contributors',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Contributors'),
            renderCell: (workspace) => (
                <TableCellLayout>
                    <div className={classes.overflowContent}>
                        <div>
                            {/* <RoleGroup
                                werePoliciesSuccessfullyFetched={dataShareIsSuccessful}
                                workspacePolicy={dataShareResponse as PolicyWorkspace}
                                onPolicySuccessfullyProcessed={performAddRoleMembersActions}
                                dataRefetching={dataShareRefetching}
                                onGraphDataError={onGraphDataError}
                                onGraphDataLoaded={() => setIsGraphDataLoading(false)}
                                onGraphDataLoading={() => setIsGraphDataLoading(true)}
                                isAddRoleMemberError={isAddRoleMemberError}
                                isDataLoading={isDataLoading}
                                policyReferenceName={policyReferenceName}
                                userRole={'contributor'}
                            /> */}
                        </div>
                    </div>
                </TableCellLayout>
            ),
        }),
    ];

    const navigate = useNavigate();

    return (
        <>
            {user?.isAdmin && (
                <div className={classes.root} data-testid="workspace-list-page">
                    <h1 className={classes.title}>{t('ManageWorkspaces.PageHeading')}</h1>
                    <div className={classes.newWorkspaceButton}>
                        <Button
                            icon={<AddIcon />}
                            appearance="secondary"
                            data-testid="new-workspace-button"
                            // onClick={() =>
                            //     MedeinaFeatures.NewSessionPage ? navigate('/sessions/new') : navigate('/')
                            // }
                            className={classes.newWorkspaceButton}
                            onClick={() => setWorkspaceDialogOpen(true)}
                        >
                            {t('ManageWorkspaces.NewWorkspaceButton')}
                        </Button>
                    </div>
                    <div className={classes.infoContainer}>{t('ManageWorkspaces.InfoContent')}</div>

                    <WorkspaceWarningMessageBar />
                    <CapacityWarningMessageBar />

                    <DataGrid
                        items={workspacesData?.value ?? []}
                        columns={columns}
                        columnSizingOptions={columnSizingOptions}
                        resizableColumns
                        selectionAppearance="neutral"
                        onSelectionChange={onSelectionChange}
                        selectedItems={selectedRows}
                        getRowId={(workspace: {workspaceId: any}) => workspace.workspaceId}
                        className={classes.gridCell}
                        data-testid="workspace-library-grid"
                    >
                        <DataGridHeader>
                            <DataGridRow>
                                {({renderHeaderCell}: any) => (
                                    <DataGridHeaderCell
                                        className={mergeClasses(classes.headerCell, classes.row)}
                                    >
                                        {renderHeaderCell()}
                                    </DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<Workspace>>
                            {({item, rowId}: any) => (
                                <DataGridRow<Workspace> key={rowId}>
                                    {({renderCell, columnId}: any) => (
                                        <DataGridCell
                                            className={classes.row}
                                            focusMode={getCellFocusMode(columnId)}
                                        >
                                            {renderCell(item)}
                                        </DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                    <Dialog
                        open={workspaceDialogOpen}
                        onOpenChange={(event, data) => setWorkspaceDialogOpen(data.open)}
                    >
                        <WorkspaceForm
                            mode="create"
                            onClose={() => setWorkspaceDialogOpen(false)}
                        />
                    </Dialog>
                </div>
            )}
        </>
    );
}
