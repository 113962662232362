import {AttributeRule, Condition, PolicyWorkspace} from '@/api/rbac/rbac.types';
import {
    MemberType,
    OptionalMemberTypeList,
    RoleObjectIdData,
    RoleType,
} from '@/components/admin/rbac/rbac.types';
import {
    ROLE_POLICY_NAME_PREFIX_MAP,
    GROUP_POLICY_KEYS,
    PARTNER_ROLES_CONDITION,
} from './rbac.constants';
import usePolicyFactory from './usePolicyFactory';
import {useEffect} from 'react';
import {getAttributeRuleById} from '.';

interface PolicyManagerProps {
    workspacePolicy: PolicyWorkspace;
    workspaceName: string;
}

export default function usePolicyManager(props: PolicyManagerProps) {
    const policyFactory = usePolicyFactory();

    const getPolicyIdForWorkspace = (role: RoleType) => {
        return `${ROLE_POLICY_NAME_PREFIX_MAP[role]}:${props.workspaceName}`;
    };

    const updateExistingPolicy = (
        roleData: RoleObjectIdData,
        workspaceName: string,
    ): PolicyWorkspace => {
        const clonedPolicy = {...props.workspacePolicy};

        //Iterate over the keys in the roleData
        for (const roleItem in roleData) {
            const role = roleItem as RoleType;

            //Get the policy name for the role
            const policyId = getPolicyIdForWorkspace(role);

            //Check if a policy record exists for the role
            const policyRecord = getAttributeRuleById(clonedPolicy, policyId);

            //If the policy record does'nt exist recreate the AttributeRule
            if (!policyRecord) {
                const attributeRules: AttributeRule[] = [];

                let keys: MemberType[] = [];
                for (const key in roleData[role]) {
                    keys.push(key as MemberType);
                }

                const getDefaultAttributeRule = policyFactory.getDefaultAttributeRule(
                    keys,
                    role as RoleType,
                    workspaceName,
                );

                attributeRules.push(getDefaultAttributeRule);

                //add attributeRules to the workspacePolicy
                clonedPolicy.properties.attributeRules.push(...attributeRules);
            }

            // If the policy record exists, first get the dnf condition
            const conditions = policyRecord?.dnfCondition;

            for (const memberType in roleData[role]) {
                // Get the condition for the memberType
                let condition = getMatchingCondition(
                    conditions as Condition[][],
                    memberType as MemberType,
                );

                // If the condition does'nt exist, create a new condition
                if (!condition) {
                    condition = policyFactory.getDefaultAttributeRuleCondition(
                        memberType as MemberType,
                        role,
                    );
                    //Add the condition to the conditions array
                    conditions?.push(condition);
                }

                // Update the condition with the new data
                const memberData = roleData[role as RoleType] as OptionalMemberTypeList;
                const arrays: string[] = memberData[memberType as MemberType] as string[];

                // Update the condition with the new data
                condition[0].attributeValueIncludedIn = arrays;
            }
        }

        return clonedPolicy;
    };

    // Toggle the partner roles condition in the policy
    function togglePartnerRolesCondition(
        roleType: RoleType = RoleType.Contributor,
    ): PolicyWorkspace {
        const clonedPolicy = {...props.workspacePolicy};
        const rolePolicyId = `${ROLE_POLICY_NAME_PREFIX_MAP[roleType]}:${props.workspacePolicy.properties.entity.referenceName}`;

        const contributorRule = clonedPolicy.properties.attributeRules.find(
            (rule) => rule.id === rolePolicyId,
        );

        if (!contributorRule) {
            return clonedPolicy;
        }

        const conditionExists = contributorRule.dnfCondition.some(
            (condition) => JSON.stringify(condition) === JSON.stringify(PARTNER_ROLES_CONDITION),
        );

        if (conditionExists) {
            // Remove the condition
            contributorRule.dnfCondition = contributorRule.dnfCondition.filter(
                (condition) =>
                    JSON.stringify(condition) !== JSON.stringify(PARTNER_ROLES_CONDITION),
            );
        } else {
            // Add the condition
            contributorRule.dnfCondition.push(PARTNER_ROLES_CONDITION);
        }

        return clonedPolicy;
    }

    const removeMemberFromPolicy = (objectId: string, memberType: MemberType, role: RoleType) => {
        const clonedPolicy = {...props.workspacePolicy};

        //Get policyName for the role
        const policyId = getPolicyIdForWorkspace(role);

        //Get the policy record for the role
        const policyRecord = getAttributeRuleById(clonedPolicy, policyId);

        //Get the dnf condition
        const conditions = policyRecord?.dnfCondition;

        //Find the member record from the conditions
        let condition = getMatchingCondition(conditions as Condition[][], memberType);

        //Get the array of objectIds after removing the to-be removed object Id
        const memberList = condition?.[0].attributeValueIncludedIn?.filter((id) => id !== objectId);

        //Assign this array to the condition
        if (condition) {
            condition[0].attributeValueIncludedIn = memberList;
        }

        return clonedPolicy;
    };

    const getMatchingCondition = (conditions: Condition[][], memberType: MemberType) => {
        return conditions?.find(
            (condition) =>
                !!condition &&
                condition.length == 2 &&
                condition[0].attributeName == GROUP_POLICY_KEYS[memberType],
        );
    };

    return {
        updateExistingPolicy,
        removeMemberFromPolicy,
        togglePartnerRolesCondition,
    };
}
