import MedeinaFeatures from '@/util/features';
import {BreadcrumbConfiguration} from './Breadcrumbs';

// A type for the route ids used in the application
// These are used to match the current route to the breadcrumb configuration
// and to navigate to the correct route, prevents typos
type MedeinaRouteId =
    | 'home'
    | 'mysessions'
    | 'newsessions'
    | 'session'
    | 'promptbooks'
    | 'promptbook'
    | 'admintour'
    | 'analysttour'
    | 'authcallback'
    | 'roleassignment'
    | 'ownersettings'
    | 'manageworkspaces'
    | 'usagemonitoring'
    | 'usagemonitoringv2'
    | 'dev'
    | 'pluginsettings';

export interface MedeinaRouteConfiguration {
    path: string;
    breadcrumb?: BreadcrumbConfiguration;
    id: MedeinaRouteId;
}

// When no breadcrumb is defined, the default breadcrumb is used like so:
// {
//     path: '/sessions',
//     id: 'mysessions',
//     breadcrumb: {
//         type: 'default',
//         key: 'mysessions'
//     },
// },

// The central place to define route paths for use in _routes RouteObject
// and to define the breadcrumb configuration for each route
export const MedeinaRoutes: MedeinaRouteConfiguration[] = [
    {
        path: '/',
        id: 'home',
        breadcrumb: {
            type: 'none',
        },
    },
    {
        path: '/sessions',
        id: 'mysessions',
    },
    {
        path: '/sessions/new/:promptbookId?',
        id: 'newsessions',
        breadcrumb: {
            type: 'component',
        },
    },
    {
        path: '/sessions/:sessionId',
        id: 'session',
        breadcrumb: {
            type: 'component',
        },
    },
    {
        path: '/promptbooks',
        id: 'promptbooks',
        breadcrumb: {
            type: 'component',
        },
    },
    {
        path: '/promptbooks/:promptbookId',
        id: 'promptbook',
        // use the same configuration as promptbooks
        breadcrumb: {
            type: 'component',
        },
    },
    {
        path: '/tour/admin',
        id: 'admintour',
        breadcrumb: {
            type: 'none',
        },
    },
    {
        path: '/tour/welcome',
        id: 'analysttour',
        breadcrumb: {
            type: 'none',
        },
    },
    {
        path: '/auth/v1/callback',
        id: 'authcallback',
        breadcrumb: {
            type: 'none',
        },
    },
    {
        path: '/role-assignment',
        id: 'roleassignment',
    },
    {
        path: '/owner-settings',
        id: 'ownersettings',
    },
    {
        path: '/manage-workspaces',
        id: 'manageworkspaces',
    },
    {
        path: '/usage-monitoring',
        id: 'usagemonitoring',
    },
    {
        path: '/dev',
        id: 'dev',
    },
];

//To be removed after V2 of Usage monitoring is launched
if (MedeinaFeatures.UsageMonitoringV2) {
    MedeinaRoutes.push({
        path: '/usage-monitoring-v2',
        id: 'usagemonitoringv2',
    });
}

if (MedeinaFeatures.UserPluginManagementAllowed) {
    MedeinaRoutes.push({
        path: '/plugin-settings',
        id: 'pluginsettings',
    });
}

// A map of routeIds to route configurations
export const MedeinaRoutesMap = MedeinaRoutes.reduce((acc, route) => {
    acc[route.id] = route;
    return acc;
}, {} as Record<MedeinaRouteId, MedeinaRouteConfiguration>);
