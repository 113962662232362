import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 'auto'),
        maxWidth: '85%',
        minHeight: '100%',
    },
    title: {
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase100,
        ),
        fontSize: tokens.spacingHorizontalXXXL,
        fontWeight: tokens.fontWeightRegular,
    },
    subtitle: {
        paddingLeft: tokens.spacingHorizontalXS,
    },
    infoContainer: {
        width: 'min(100%,500px)',
        ...shorthands.padding(
            tokens.spacingVerticalXXL,
            tokens.spacingHorizontalNone,
            tokens.spacingVerticalXXL,
            tokens.spacingHorizontalNone,
        ),
    },
    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
    },
    skillFormWrapper: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
    },

    // For grid
    filteringInvestigations: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        textAlign: 'center',
        justifyContent: 'center',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalS),
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase400,
        ),
    },
    filterDialog: {
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: tokens.colorNeutralBackground1,
        alignItems: 'center',
        ...shorthands.borderRadius(tokens.spacingHorizontalS),
        ...shorthands.margin(
            tokens.lineHeightHero700,
            tokens.spacingHorizontalXS,
            tokens.lineHeightBase400,
        ),
    },
    gridCell: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        ...shorthands.padding('0', tokens.spacingHorizontalL),
    },
    headerCell: {
        '& > button': {
            paddingBottom: tokens.spacingVerticalS,
            paddingTop: tokens.spacingVerticalS,
        },
    },
    overflowContent: {
        wordBreak: 'break-word',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: '-webkit-box',
    },
    overflowNameContent: {
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        display: '-webkit-box',
        maxWidth: '90%',
    },
    description: {
        // whiteSpace: 'nowrap',
        lineHeight: tokens.lineHeightBase200,
        textOverflow: 'ellipsis',
        overflowY: 'hidden',
        overflowX: 'hidden',
        maxWidth: '90%',
    },
    row: {
        ...shorthands.margin(tokens.spacingVerticalS, 'auto', tokens.spacingVerticalS),
    },
    promptCount: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase400,

        '& svg': {
            fontSize: tokens.fontSizeBase400,
            marginBottom: '-4px',
        },
    },
    runIconHeader: {
        ...shorthands.margin('auto', tokens.spacingHorizontalXL),
        fontSize: tokens.fontSizeBase500,
    },
    runButton: {
        ...shorthands.margin('auto', tokens.spacingHorizontalM),
    },
    stickyActions: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalL,
        backgroundColor: tokens.colorNeutralBackground2,
    },
    inputsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '2',
        flexDirection: 'row',
        width: '95%',
    },
    input: {
        height: tokens.fontSizeBase500,
        minWidth: 'auto',
        ...shorthands.margin(tokens.spacingHorizontalXS),
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    highlight: {
        fontWeight: tokens.fontWeightBold,
        backgroundColor: 'inherit',
        color: 'inherit',
    },
    newWorkspace: {
        position: 'sticky',
        top: tokens.lineHeightBase500,
        zIndex: 2,
        marginLeft: 'auto',
        marginTop: '-22px',
        justifyContent: 'flex-end',
    },
    switchCapacityBtn: {
        ...shorthands.margin(
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalS,
            tokens.spacingVerticalNone,
            tokens.spacingHorizontalNone,
        ),
    },
    newWorkspaceButton: {
        position: 'sticky',
        top: tokens.lineHeightBase500,
        zIndex: 2,
        marginLeft: 'auto',
        marginTop: '-22px',
        justifyContent: 'flex-end',
    },
    manageButton: {
        paddingRight: tokens.spacingHorizontalS,
        display: 'inline-block',
        '& .delete-button:hover': {
            backgroundColor: 'red',
        },
    },
    CapcityMenuList: {
        width: '100%',
        ...shorthands.margin(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    },
    errorMenuButton: {
        border: '1px solid ' + tokens.colorPaletteDarkOrangeBorder1,
        backgroundColor: tokens.colorPaletteDarkOrangeBackground1,
        color: 'white',
        '&:hover': {
            backgroundColor: tokens.colorPaletteDarkOrangeBorder1,
            color: 'white',
        },
    },
});
