import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {DismissIcon} from '@/components/ui/icons';
import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    MenuTriggerChildProps,
    Tooltip,
} from '@fluentui/react-components';
import useClasses from './FilterPills.styles';
import {PillMenuItems} from './PillMenuItems';
import {getDisplayValue, removeFilterByCategory, removeFilterByValue} from './Filter.utils';
import useGetSelectedFilterPillsLabels from './useGetSelectedFilterPillsLabels';

interface FilterPillsProps {
    filters: SelectedSearchFacet[];
    onFiltersUpdated: (filters: SelectedSearchFacet[]) => void;
    copilotExperienceMap: KeyValueFilterDetails[];
}

export default function FilterPills(props: FilterPillsProps) {
    const classes = useClasses();

    const {filters, copilotExperienceMap} = props;
    const {filterPillsNumber, multiSelectedLabels, singleSelectedLabels, getMoreFiltersLabel} =
        useGetSelectedFilterPillsLabels({filters, copilotExperienceMap});

    return (
        <>
            {props.filters.slice(0, filterPillsNumber).map((filter, index) => (
                <Menu key={index}>
                    <MenuTrigger disableButtonEnhancement>
                        {(prop: MenuTriggerChildProps<'button'>) => (
                            <div className={classes.dropdownTriggerBtn}>
                                <Button
                                    {...prop}
                                    appearance="transparent"
                                    className={classes.menuButton}
                                >
                                    <div className={classes.ellipsisText}>
                                        {filter.values.length == 1 ? (
                                            <Tooltip
                                                content={getDisplayValue(
                                                    filter,
                                                    props.copilotExperienceMap,
                                                )}
                                                relationship="label"
                                            >
                                                <span>{singleSelectedLabels[filter.category]}</span>
                                            </Tooltip>
                                        ) : (
                                            <>{multiSelectedLabels[filter.category]}</>
                                        )}
                                    </div>
                                </Button>
                                <Button
                                    appearance="transparent"
                                    icon={<DismissIcon />}
                                    onClick={() => {
                                        const updatedFilters = removeFilterByCategory(
                                            filter.category,
                                            props.filters,
                                        );
                                        props.onFiltersUpdated(updatedFilters);
                                    }}
                                    className={classes.dismissButton}
                                />
                            </div>
                        )}
                    </MenuTrigger>

                    <PillMenuItems
                        filter={filter}
                        onFilterRemoval={(category, value) => {
                            {
                                const updatedFilters = removeFilterByValue(
                                    category,
                                    value,
                                    props.filters,
                                );
                                props.onFiltersUpdated(updatedFilters);
                            }
                        }}
                        index={index}
                        copilotExperienceMap={props.copilotExperienceMap}
                    ></PillMenuItems>
                </Menu>
            ))}

            {props.filters.length > filterPillsNumber && (
                <Menu key="moreFilters">
                    <MenuTrigger disableButtonEnhancement>
                        <Button className={classes.moreFiltersButton} appearance="transparent">
                            {getMoreFiltersLabel(props.filters.length - filterPillsNumber)}
                        </Button>
                    </MenuTrigger>

                    <MenuPopover>
                        {props.filters.slice(filterPillsNumber).map((filter, index) => (
                            <MenuList key={index}>
                                {filter.values.length == 1 ? (
                                    <MenuItem key={index} className={classes.menuItem}>
                                        {singleSelectedLabels[filter.category]}
                                        <Button
                                            icon={<DismissIcon />}
                                            size="small"
                                            appearance="transparent"
                                            onClick={() => {
                                                const updatedFilters = removeFilterByCategory(
                                                    filter.category,
                                                    props.filters,
                                                );
                                                props.onFiltersUpdated(updatedFilters);
                                            }}
                                        />
                                    </MenuItem>
                                ) : (
                                    <Menu key={index}>
                                        <MenuTrigger disableButtonEnhancement>
                                            <MenuItem key={index}>
                                                {multiSelectedLabels[filter.category]}
                                            </MenuItem>
                                        </MenuTrigger>

                                        <PillMenuItems
                                            filter={filter}
                                            onFilterRemoval={(category, value) => {
                                                const updatedFilters = removeFilterByValue(
                                                    category,
                                                    value,
                                                    props.filters,
                                                );
                                                props.onFiltersUpdated(updatedFilters);
                                            }}
                                            index={index}
                                            copilotExperienceMap={props.copilotExperienceMap}
                                        ></PillMenuItems>
                                    </Menu>
                                )}
                            </MenuList>
                        ))}
                    </MenuPopover>
                </Menu>
            )}
        </>
    );
}
